.insta-landing-page {
  background-color: #fafafa;
  background-image: url("./brushes.jpg");
  background-size: cover;
  color: #fff;
}

.insta-landing-page-2 {
  background-color: #fafafa;
  background-image: url("./hands.jpg");
  background-size: cover;
  color: #fff;
  gap: 20px;
}

@media (max-width: 768px) {
  .insta-landing-page-2 {
    flex-direction: column !important;
  }
}

.insta-landing-button {
  border-radius: 5px;
  width: 100%;
}

.insta-landing-main {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
}

.rounded-card {
  border-radius: 10px;
  max-height: 1000px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  padding: 1rem;
  color: black;
  border: 2px solid rgba(0, 0, 0, 1);

  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insta-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
