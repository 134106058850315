:root {
  --color-primary: #09527f;
}

.change-bg-holder {
  /* position: absolute; */
}

.edit-board-form {
}

.submit-btn-holder {
}

.logo-btn-holder {
  position: absolute;
}

.btn-form {
  background: #09527f !important;
  color: white !important;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 1.5rem;
  border: none;
  box-shadow: 0rem 0.25rem 1rem black;
  z-index: 1;
  transition: all 0.25s;
}

.btn-form-disabled {
  background: #888 !important;
  color: white !important;
  font-size: 1.25rem;
  padding: 0.5rem;
  border-radius: 1.5rem;
  border: none;
  box-shadow: none;
  z-index: 1;
}

.btn-form.responsive, .btn-form-disabled.responsive {
  width: 20vw;
}

.btn-form:active {
  scale: 1.1;
  transform: translateY(0.25rem);
  transition: 0.1s;
}

.btn-form:hover {
  filter: brightness(0.8);
  scale: 1.1;
}

.btn-form.submit {
  /* position: absolute; */
  /* top: 75%; */
  float: right !important;
}

.btn-primary-modal {
  background-color: #09527f !important;
  color: white !important;
  transition: all 0.3s;
}

.btn-primary-modal:hover {
  filter: brightness(0.8);
  transition: all 0.3s;
}

.img-modal {
  height: 108px;
  width: 192px;
  padding: 0rem;
  margin: 0.5rem;
  overflow: auto;
}

.img-modal:hover {
  border-style: solid;
  border-width: 0.5rem;
  border-color: #80c4ee8c;
}

.img-modal.toggle {
  border-style: solid;
  border-width: 0.5rem;
  border-color: #09527f;
}

.img-display {
  display: block;
  padding: 0.25rem;
  margin: 0.25rem;
}

.img-display.small {
  height: 108px;
  width: 192px;
}

.img-display.medium {
  height: 216px;
  width: 384px;
}

.modal {
  background-color: #85d0ff5b;
}

.logo-container {
  display: inline-block;
  width: 100px;
  height: 100px;
}

.logo-modal {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo-modal:hover {
  border-style: solid;
  border-width: 0.5rem;
  border-color: #80c4ee8c;
}

.logo-modal.toggle {
  border-style: solid;
  border-width: 0.5rem;
  border-color: #09527f;
}

.modal-body {
  background-color: rgb(148, 148, 148);
}

.logo-inputs-container {
  width: 100%;
}

.input-file-container {
  margin: auto;
  position: absolute;
  display: inline-block;
  vertical-align: center;
  transform: translateY(25%);
  left: 30%;
}

.input-file {
}

.upload-sitemaps-row{
  margin-top: 2rem !important;
}

.upload-sitemaps-error{
  color: orangered;
  margin-bottom: 0 !important;
}

.logo-preview-container {
  width: 7rem;
  height: 7rem;
  position: absolute;
  top: 0rem;
  left: 75%;
}

.logo-preview-container .logo-preview {
  width: 100%;
  height: auto;
}

.device-form-group {
  display: inline-block;
  vertical-align: top;
  margin: 0rem 1rem 1rem 1rem;
}

.input-list {
  list-style: none;
}

.device-form--input input {
  margin-bottom: 0.25rem;
  display: inline-block;
  vertical-align: center;
}

.status {
  display: inline;
  position: absolute;
}

.status.error {
  color: red;
}

.disclaimer {
  font-size: 0.8rem;
  color: #888;
}

.reset-form {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.reset-form--btn {
  background-color: rgb(165, 165, 165);
  font-size: 1rem;
  scale: 1.1;
  border-radius: 15px;
  border-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 0.1s;
}

.reset-form--btn:active {
  scale: 1;
}

.wrapper {
  display: flex;
}

.wrapper > .wrapper__content {
  font-size: 1.25rem;
  max-width: 25rem;
  /* color: white;
    background: gray; */
  margin: 2px;
  margin-bottom: 1rem;
  padding: 2px;
  border-radius: 3px;
  flex: 1;
  transition: all 0.2s;
}

.edit-form-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.edit-form-row {
  margin-top: 1rem !important;
}

.edit-form-btn {
  margin-top: 0rem;
}
