.directory-hidden {
  display: none;
}

.dir-page-container {
  width: 1920px;
  height: 1080px;
  overflow: hidden !important;
  user-select: none;
  /* background: url("colors.jpg"); */
}
.card-box {
  flex-shrink: 1;
  min-width: 192px;
  min-height: 47px;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.349);
  margin-right: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 7px;
  font-family: "Raleway", sans-serif;
  border: 3px solid rgb(255, 255, 255);
}
.card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  background: #ffffff;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Raleway", sans-serif;
}

/* .card-text-container > div {
  margin: 1px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid black;
} */

.small-card-heading {
  margin: 1px 0;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  width: 100%;
  /* display: flex; */
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid rgb(59, 59, 59);
}

.heading-business-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 75%;
}

.small-card-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-card-footer > .footer-contact-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
}

.card-img-box {
  width: 47px;
  height: 47px;
  background: black;
}

.sitemap-container > img {
  width: 80%;
}
.card-list-container {
  margin-top: 5px;
  width: 100%;
  height: 715px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
}
.info-container {
  margin-top: 5px;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0px inset black;
  background-color: rgba(233, 233, 233, 0.877);
  height: 250px;
  color: rgb(0, 0, 0) !important;
  font-family: "Raleway", sans-serif;
}
.contact-container,
.featured-cards {
  display: flex;
}
.featured-cards {
  width: 300px;
  height: 190px;
  justify-content: center;
  align-items: center;
}
.contact-container {
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 410px;
}

.featured-text {
  width: 300px;
}

.featured-text-item {
  max-width: 175px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.featured-img {
  width: 100px;
  height: 100px;
  background: black;
  border: 2px solid black;
}
.featured-card-box {
  display: flex;
}

.video-container {
}

.map-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  border: none;
}
.map-container > img {
  max-height: 250px;
}
.main-info > .map-container > img {
  filter: contrast(1000%) invert(100%) sepia(100%) saturate(10000%)
    hue-rotate(90deg);
}

.iframe-holder {
  pointer-events: none;
  height: 100%;
  width: 100%;
}

/* youtube ad on salon SCREENSAVER page */
.iframe-container {
  /* display: flex;
  justify-content: center; */
  box-shadow: 5px 3px 9px rgba(0, 0, 0);
  border: 2px solid black;
  height: 248px;
  width: 440px;
}

.iframe {
  display: flex;
  height: 240px;
  width: 440px;
  /* border-radius: 15px; */
  justify-content: center;
  box-shadow: 5px 3px 9px rgba(0, 0, 0);
  border: 2px solid black;
  position: absolute;
  margin-top: -10px;
}

.video {
  overflow: hidden;
  position: absolute;
  /* transition: all 0.3s ease-out; */
}

.video-main-view {
  position: absolute;
  top: 730px;
  left: 533px;
}

.video-single-view {
  position: absolute;
  top: 39vh;
  left: 8px;
}

/* .screensaver-vid {
  height: 240px;
  width: 440px;
} */

/* youtube ad on salon BOARD PAGE */
.screensaver-ad-container {
  display: flex;
  height: 478px;
  width: 850px;
  align-self: center;
  position: absolute;
  top: 50%;
  left: 52%;
}

.grid-list {
  margin-top: 25px;
  display: grid;
  gap: 10px;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}
.info-header {
  font-weight: bold;
  font-size: 1.25rem;
  font-family: "Raleway", sans-serif;
}
.dir-footer {
  width: 100%;
  height: 105px;
  display: flex;
}
.filters {
  padding: 20px 70px;
  color: black;
  width: 95%;
  height: 100%;

  align-items: center;
  display: flex;
  justify-content: space-between;
}
.filter-img {
  display: flex;
  justify-content: center;
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #131212;
  color: white;
}
.ada-container {
  display: flex;
  width: 7%;
}
.ada-container > * {
  margin: auto;
  width: 70%;
}
.screensaver {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  background: rgba(0, 0, 0, 0.97);
  animation: fadeIn 1s ease-in;
}

/* IMAGE SLIDER ON SALON SCREENSAVER*/
.picture-orbit {
  /* overflow: hidden; */
  transform-origin: left top;
  transition: transform 3s;
}

.circle {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  justify-content: center;
  margin-left: 20rem;
  margin-top: 20%;
}

.images {
  height: auto;
  width: auto;
  top: 8%;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* animation: spinningTitle 10s infinite linear !important; */
}

.images > img {
  margin: 0px;
  position: relative;
  max-height: 175px;
  height: auto;
  max-width: 175px;
  width: auto;
  padding: 15px;
  /* animation: spinningTitle 5s infinite linear !important; */
}

/* screensaver banner */
.screensaver-side {
  height: 100%;
  width: 36rem; /* 32.75rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  border-radius: 0 85% 85% 0/0 50% 50% 0;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(233, 233, 233, 0.877);
  /* overflow: hidden; */
  padding: 20px;
  overflow: hidden;
}

.directory-time {
  text-align: left;
  top: 2rem;
  margin-left: 20px;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  position: relative;
}

.bottom {
  position: fixed;
  width: 100%;
  bottom: 3%;
  background: none;
}

.bottom > * {
  background: none;
  color: white !important;
  border: none;
}

.salon-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  width: 60rem;
  height: 200px;
  background: rgb(207, 170, 170);
  float: right;
  margin-top: 4%;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
}

/* screensaver banner text */
.top-text > h2 {
  font-size: 40px !important;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
}

.mid-text {
  font-size: 50px !important;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 1;
}

.bottom-text {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
}

.screensaver-card-container {
  float: right;
  display: flex;
  flex-direction: row;
  margin-top: 35vh;
  position: relative;
  right: -48%;
  border: 1px white;
  animation: zoomInUp;
  animation-duration: 3s;
}

/* Contact Card on Screensaver*/
.contact-card {
  width: 27rem;
}

.accessibility-container {
  position: absolute;
  bottom: 6.5rem;
  height: 250px;
  width: 100%;
  padding: 1rem 5rem;
  background-color: rgba(231, 231, 231, 1);
  overflow: hidden;
  z-index: 1;
}

.accessibility {
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.accessibility-item {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  flex: 1 1 100px;
  width: auto;
  padding: 0.25rem;
  margin: 0.25rem;
  border: 1px solid rgba(123, 123, 123, 0.9);
  border-radius: 3px;
  box-shadow: 1px 3px 5px black;
}

.qr-bg {
  /* transform: scale(0.6); */
  border-radius: 4px;
  box-shadow: 5px 3px 9px rgba(0, 0, 0);
  width: fit-content;
  position: relative;
  /* top: 25vh;
  right: -6vw; */
}

.qr-bg > svg {
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
  border-radius: 4px;
}
.qr-bg > h1 {
  border-radius: 4px;
}

.mx-3 {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
}

.current-filter {
  text-align: center;
  width: 300px;
  text-overflow: clip;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinningTitle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
