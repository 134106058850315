.login-container {
  width: 100%;
  height: 100vh;
  background-image: url("./login-background.svg");
  background-position: center center;
  background-size: cover;
}
.login-sidebar {
  background: #fbfbfb;
  width: 35%;
  height: 100vh;
  position: relative;
  min-width: 550px;
}

.login-logo {
  width: 50%;
}
.login-form {
  width: 50%;
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-input {
  width: 100%;
}
.login-submit {
  background: #09527f !important;
  color: white !important;
  width: 40%;
  margin-top: 2em;
  margin-right: auto;
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 550px) {
  .login-sidebar {
    min-width: 0px;
    width: 100%;
  }
  .login-logo {
    width: 80%;
  }
  .login-form {
    width: 80%;
  }
}
