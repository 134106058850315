.signage-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background: url("filler1.jpg");
}

.side-column-container {
  height: 100%;
  width: 31rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.9);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-top: 20px;
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
}

/* top screen banner */
.banner-header {
  text-align: center;
  margin-top: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

/* 'X' button to hide sidebar */
.banner-remove-button {
  position: absolute;
  left: inherit;
  top: inherit;
  background-color: rgba(128, 128, 128, 0.9);
  border: none;
  font-family: "Raleway", sans-serif;
}

/* Youtube ad video */
.signage-video {
  height: 265px;
  width: 470px;
  border-radius: 10px;
  box-shadow: 5px 3px 9px rgba(0, 0, 0);
}

.signage-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* image slideshow */
.slideshow {
  margin: 0;
  display: block;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  /* display: inline-block; */
  height: 100vh;
  width: 100vw;
}

/* .slide-holder {
  width: 100vw;
} */

/* weather widget */
.grid-container {
  padding: 0.5rem;
  display: inline-block;
  bottom: 0;
  width: 73px;
  height: 168px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(24, 24, 27, 1);
  border-radius: 10px;
  border: 1px solid #eee;
  text-align: center;
}

.weather-container {
  display: inline-flex;
}

.short-forecast {
  font-size: 14px;
  line-height: 1.1;
}

img.weather-icon {
  border-radius: 4px;
  height: 40px;
  width: 40px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.marquee-container {
  display: inline-block;
  color: white;
  font-weight: 300;
  margin-top: 1rem;
}

.ticker-icon {
  word-spacing: 2px;
  letter-spacing: 2px;
}

.powered-container {
  width: 100%;
  text-align: center;
  margin: 1rem 0 0.5rem 0;
}

.powered-by {
  background-color: black;
  opacity: 0.6;
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered-text {
  font-weight: bold;
}

.qr-code-holder {
  height: auto;
}

.single-weather {
  background: rgba(50, 50, 50, 0.9);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  font-family: "Asap", sans-serif;
}

.single-weather > h3 {
  font-family: "Asap", sans-serif;
  border-bottom: solid 1px rgb(207, 205, 205);
}

.weather-background {
  position: relative;
  height: 100%;
}

.single-weather > p {
  font-size: 1.5rem;
  font-weight: 300;
}

/* hourly weather */
.hourly-weather {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 37%;
  background-color: rgba(58, 58, 58, 0.9);
  border-radius: 5px;
  box-shadow: 0px 5px 15px black;
}

.hourly-weather__single {
  width: calc(100% / 8);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  font-family: "Asap", sans-serif;
}

.hourly-weather__single:not(:last-child):after {
  content: "";
  position: absolute;
  top: 0;
  height: 75%;
  width: 1px;
  /* background-color: #000; */
  margin: auto;
  transform: translate(30px, 20%);
}

.hourly-weather__single > .period-time {
  font-family: "Asap", sans-serif;
  font-size: 22px;
  text-align: center;
  border-bottom: solid 1px rgb(207, 205, 205);
}

.hourly-weather__single > .period-temp {
  font-family: "Asap", sans-serif;
  font-size: 20px;
  text-align: center;
  border-bottom: solid 1px rgb(207, 205, 205);
}

.hourly-weather > .weather-line-chart {
  position: absolute;
  top: 50%;
  height: 25%;
  width: 100%;
  z-index: 100;
  padding: 3px 0;
  transform: translateY(-50%);
}

/* announcement widget */
.announcement-container {
  width: 100%;
  height: 100%;
  max-height: 10rem;
  background-color: rgba(58, 58, 58, 0.9);
  box-shadow: 0px 5px 15px black;
  padding: 0.5rem;
  border-radius: 5px;
}

.announcements-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.announcement-header {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

.announcement-message {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: white;
  font-family: "Raleway", sans-serif;
}

/* .announcement-date {
list-style: none;
padding: 0;
margin: 0;
color: black;
display: inline-block;
} */
