.new-card-input {
  width: 100% !important;
  border-radius: 4px;
  margin-bottom: 50px;
}

.new-card-btn {
  width: 25%;
}

.all-card-header {
  width: 26.6%;
}

.card-list-item {
  max-width: 254px !important;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.card-info-item {
  max-width: 230px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new-card-preview-img {
  width: 100px;
}

.multi-image {
  width: 150px;
}

.test {
  height: 300px;
  width: 1000px;
}

.room-assign-small-col {
  width: 10%;
  text-align: center;
}

.room-assign-med-col {
  width: 15%;
  text-align: center;
}

.room-assign-large-col {
  width: 32.5%;
  text-align: center;
}

.room-assign-clear-btn {
  background: #dc3545 !important;
  color: white !important;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 2rem;
  border: 0;
}
