.single {
  display: flex;
  flex-direction: column;
}

.single-business-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.single-list {
  margin: 0;
}

.single-list > li {
  font-size: 18px;
}

.single-list > li:not(:first-of-type) {
  margin-top: 10px;
}
.single-insta-image {
  width: 350px;
  position: relative;
  right: 20px;
}

.single-sitemap-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 1rem 2.5rem 1rem 1rem;
}

.single-sitemap {
  max-width: 100%;
  height: 100%;
  width: auto;
  margin-right: 1rem;
}

.home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 6.5rem;
  border-radius: 50%;
}
.home-button img {
  width: 65%;
  aspect-ratio: 1;
}

.single-top-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33vh;
}

.single-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(233, 233, 233, 0.7);
  color: black;
  width: 100%;
  height: 80%;
  padding: 0 2rem;
}

.single-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56vh;
  align-items: center;
  /* column-gap: 20px; */
}

.single-video {
  margin-right: 5rem;
}

.single-contact-details {
  padding-left: 20px;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.single-header {
  max-width: 800px !important;
  font-size: 52px !important;
  font-family: "Raleway", sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.single-about-services-header {
  font-size: 52px !important;
  font-family: "Raleway", sans-serif;
}

.single-card-image {
  width: 232px;
  border-radius: 50%;
  height: 232px;
  border-radius: 15px;
  padding: 5px;
}

.single-contact-info {
  display: flex;
  align-items: center;

  max-width: 100%;
  max-height: 100%;
  overflow-y: hidden;
}

.single-list-item {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.single-list-info {
  max-width: 400px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.single-qr {
  float: right;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  /* margin: 0.5rem 0 0.5rem 1.5rem; */
}

.insta-slideshow {
  display: block;
  overflow: hidden;
  width: 290px;
  max-height: 95%;
  aspect-ratio: 1/1;
  margin: 1rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
}

.insta-slider {
  white-space: nowrap;
  transition: ease 1000ms;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insta-content-holder {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 290px;
  margin-top: 0px;
}

.insta-content-holder > img {
  max-height: 100%;
  max-width: 100%;
  margin-top: 0px;
  height: auto;
  width: 290px;
}

.insta-content-holder > video {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 290px;
}

.single-about-services-container {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  width: 45%;
  padding-right: 2rem;
  height: 100%;
}

.single-about-services-container > p {
  overflow-y: auto;
  height: 100%;
}

.single-about {
  display: flex;
  flex-direction: column;
  height: 50%;
}

.single-about > p {
  overflow-y: auto;
}

.single-services {
  display: flex;
  flex-wrap: wrap;
  height: 50%;
  overflow: hidden;
}

.services-categories {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  column-gap: 10px;
  width: 100%;
  max-height: 100%;
  text-align: center;
}

.services-categories > .category {
  height: 100%;
  min-width: 10%;
  max-width: 15%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category > img {
  max-height: 25%;
  width: auto;
  background: black;
  border-radius: 50%;
}

.cate .single-bottom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
}
.single-bottom-row {
  display: flex;
  width: 100%;
  justify-content: center;
}
