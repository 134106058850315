.general-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  display: flex;
  background-color: black;
}

.general-screensaver {
  z-index: 2;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.97);
  opacity: 0;
  transition: 500ms ease;
}

.general-screensaver-side {
  height: 100%;
  width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  border-radius: 0 85% 85% 0/0 50% 50% 0;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(233, 233, 233, 0.877);
  color: rgb(221, 221, 221);
  padding: 20px;
  overflow: hidden;
}

.general-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  width: 60rem;
  height: 200px;
  background: rgb(207, 170, 170);
  color: white;
  float: right;
  margin-top: 4%;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
}

.general-screensaver-touch {
  display: flex;
  position: fixed;
  bottom: 10px;
  margin-left: 30px;
  flex-direction: column;
  justify-content: space-between;
  width: 38rem;
  height: 125px;
  padding: 2px;
  color: white;
  float: right;
  margin-top: 4%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.icon-container {
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  bottom: 1px;
  padding-right: 0;
}

.touch-text {
  font-family: "Raleway", sans-serif;
  font-size: 50px;
}

.general-side-column-container {
  height: 100%;
  width: 50rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.general-side-column {
  position: relative;
  min-width: 31rem;
}

.general-banner-header {
  text-align: center;
  margin-top: 0.6rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.general-video {
  height: 20rem;
  width: 30rem;
  border-radius: 10px;
  box-shadow: 5px 3px 9px rgba(0, 0, 0);
  position: relative;
}

.general-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* These will probably become forms later but for now, it will be a qr code */
/* .general-form-btn {
  display: none;
  position: relative;
  height: 2rem;
  width: 13rem;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 5px 3px 0.5rem rgba(0, 0, 0, 0.8);
} */

.general-qr-code-holder {
  height: auto;
  width: auto;
}

.general-widget-container {
  height: 30rem;
  width: 95%;
}

.general-sitemap {
  margin: 0;
  min-width: 80%;
  max-height: 100%;
  max-width: 80%;
  /* width: 80%; */
  width: auto;
  height: auto;
}

.general-main-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.general-main-top {
  width: 100%;
  height: 80%;
  display: flex;
}

.general-main-bottom {
  height: 20%;
}

.general-description {
  position: relative;
  width: 20%;
  height: 100%;
  background-color: rgba(230, 230, 230, 1);
  padding: 10px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
  transition: transform 0.5s ease;
  word-wrap: break-word;
  overflow-y: auto;
}

.general-description-no-text {
  transform: translateX(100%);
}

.general-description > h3 {
  width: 100%;
  font-size: 1.6rem;
  padding-bottom: 1rem;
  border-bottom: solid 1px black;
}

.general-directory-container {
  display: none;
  float: right;
  height: 100%;
  width: 20%;
}

.general-directory-time {
  position: relative;
  bottom: 0;
  text-align: left;
  top: 1rem;
  margin-left: 20px;
  padding: 0px 14px;
  width: 35rem;
  border-radius: 4px;
  font-size: 55px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}

.general-logo {
  margin: 0;
  height: 15rem;
  width: 15rem;
}

/* Directory will just be static now */
/* .general-directory-btn {
  display: none;
  position: absolute;
  bottom: 0;
  height: 2rem;
  width: 8rem;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 5px 3px 0.5rem rgba(0, 0, 0, 0.8);
} */

/* image slideshow */
.slideshow {
  margin: 0;
  display: block;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  height: 100vh;
  width: 100vw;
}

.general-navigation {
  padding: 10px;
  background-color: rgb(216, 216, 216);
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.general-navigation::-webkit-scrollbar,
.general-description::-webkit-scrollbar {
  width: 20px;
}

.general-navigation::-webkit-scrollbar-track,
.general-description::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.general-navigation::-webkit-scrollbar-thumb,
.general-description::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  border-radius: 100px;
}

.navigation-box {
  width: 10%;
}

.navigation-button {
  text-transform: capitalize;
  font-size: 13px !important;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.outlined {
  outline-style: solid;
  outline-width: 5px;
  outline-offset: 5px;
  outline-color: cornflowerblue;

  animation: 0.1s ease-out outlinePop;
}

@keyframes outlinePop {
  0% {
    outline-offset: 2px;
  }

  100% {
    outline-offset: 8px;
  }
}
