input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #09527f;
}
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
a {
  margin: 0;
  padding: 0;
  /* text-decoration: none !important;
  color: white !important; */
}
.alumni {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.unstyled-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
/* start navigation */
.nav-container {
  width: 100%;
}
.nav-container a {
  color: white;
  text-decoration: none;
}
.nav-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.nav-logo-container > img {
  width: 15%;
  min-width: 288px;
}
.nav-link-container {
  background: #09527f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.link {
  font-size: 1.25rem;
  padding: 10px 1.5em;
}
.link:hover {
  color: #09527f !important;
  background: #fbfbfb;
}
.active-link {
  background: #fbfbfb;
  color: #09527f !important;
}

.logout-btn {
  color: #09527f !important;
}

.nav-dropdown {
  font-family: "Assistant";
}

.dropdown-item > .dropdown-link {
}

.dropdown-item {
  color: #333 !important;
  text-align: center !important;
  padding-bottom: 0.5rem !important;
}

.dropdown-item:active {
  background: grey !important;
}

.nav-dropdown > .custom-dropdown-toggle {
  background-color: #09527f !important;
  font-family: "Assistant";
  font-size: 1.25rem;
  padding: 10px 1.5em;
  border: none;
  border-radius: 0;
  height: 100% !important;
  width: 100%;
}

/* start general rules */
.page-container {
  padding: 70px 0px;
  background: #f3f3f3;
}
.sub-header {
  font-family: "Assistant";
  font-size: 2.5rem;
}
.inner-container {
  border-radius: 4px;
  background: white;
  padding: 2em;
  min-height: 70vh;
  box-shadow: 5px 5px 0px #0000000d;
}
.btn-standard {
  background: #09527f !important;
  color: white !important;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 2rem;
  border: 0;
}
.table-btn {
  font-size: 14px !important;
}

.td-boards-btn {
  width: 10%;
  overflow: hidden;
}

.td-flex {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem !important;
  min-width: 100px;
}

.custom-suite-name {
  width: 50%;
  margin-right: 1rem;
}

.form-container {
  padding-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.form-err {
  position: absolute;
  top: -25px;
  color: red;
}
.form-group {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.fancy-input {
  border: 1px solid #70707073 !important;
  border-radius: 4px;
  padding: 7px 7px;
  margin-right: 1rem;
}
.generic-modal {
  padding: 2em;
  background: #f3f3f3;
  border-radius: 4px;
}
.generic-modal > button {
  margin-right: auto;
}
.change-pass-modal {
  display: flex;
  flex-direction: column;
}
.change-pass-modal > {
  margin: 1em 0em;
}
.modal-input-group > * {
  display: block;
}
.modal-input-group {
  margin: 20px 0px;
}
.generic-modal-header {
  font-size: 1.5rem;
}
label {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.fancy-input:focus {
  border: 1px solid #09527f;
}

.suite-status {
  position: fixed;
  top: 2rem;
  left: 75vw;
  padding: 5px;
  border-radius: 8px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: lightgreen;
  z-index: 15;

  animation: fadeInDown 0.3s ease-out;
}

.content {
  height: 100%;
  width: 100%;
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 898px) {
}
